import moment from 'moment'
 import { DEFAULT_LANGUAGE } from '../constants/app'
const SEXE = {
  m: 'Mâle',
  f: 'Femelle'
}

moment.locale(DEFAULT_LANGUAGE)

export const showTime = value => {
  if (value === null) { return '-' }
  let d = moment(value)
  let now = moment()
  if(now.format('X') - d.format('X')> 3600)  return d.format('llll:ss')
  return d.fromNow()

}

export const deepSearch = (object, search) =>{
  let elements = Object.values(object)
  for(var i = 0; i < elements.length; i++){
    if(elements[i] !== null && typeof elements[i] === 'string'  && elements[i].toLowerCase().includes(search.toLowerCase())){
      return true
    }
  }
  return false
}

export const humanReadableTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.fromNow()
}

export const age = value => {
  if (value === null) { return '-' }
  var diff = moment().diff(moment(value), 'milliseconds');
  var duration = moment.duration(diff);
  return duration.years() + ' ans '+ duration.months() + ' mois ' + duration.days() + ' Jours';
}

export const capitalize = value => {
  if (typeof value !== 'string') { return 'undefine' }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const systemReadableTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('L')
}

export const systemDateTime = value => {
  if (value === null) { return '-' }
  let d = moment(value);
  return d.format('llll:ss')
}

export const truncate = (value, length) => {
  return value.substring(0, length)
}

export const libelle = (objet = {}) => {
  if (objet === null | objet === undefined) { return '-' }
  if (objet) { return objet.libelle }
  return 'Error in Objet'
}

export const sexe = (placeholder) => {
  if (placeholder !== 'f' && placeholder !== 'm') return 'error sexe'
  return SEXE[placeholder]
}
