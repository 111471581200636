<template>
  <div id="app">
    <router-view v-if="!logged" />
    <layout v-else />
  </div>
</template>
<script>
import Layout from './components/template/layout.vue'
import {isLogin} from './plugins/authService'
export default {
  components: { Layout },
  data(){
    return {
      logged: isLogin()
    }
  },
  computed: {

  }
}
</script>

<style>

</style>
