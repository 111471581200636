import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import {isLogin} from '../plugins/authService'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = isLogin()
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  if (!authRequired && loggedIn) {
    return next({ name: 'home' })
  }
  next()
})

export default router
