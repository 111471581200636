import gql from 'graphql-tag'


/** MODE_VIE */
export const ADD_MODE_VIE = gql`
mutation Mutation($mode: ModevieInput) {
  addModevie(mode: $mode)
}
`
export const UPDATE_MODE_VIE = gql`
mutation Mutation($uid: String!, $mode: ModevieInput) {
  updateModevie(uid: $uid, mode: $mode)
}
`
export const DELETE_MODE_VIE = gql`
mutation Mutation($uid: String!) {
  deleteModevie(uid: $uid)
}
`
export const SUBSCRIBE_MODE_VIE= gql`
subscription Subscription {
  modevie {
    type
    data {
      uid
      libelle
    }
  }
}`

/** TYPE_CHIRURGIE */
export const ADD_TYPE_CHIRURGIE = gql`
mutation Mutation($type: TypeChirurgieInput!) {
  addTypeChirurgie(type: $type)
}
`
export const UPDATE_TYPE_CHIRURGIE = gql`
mutation Mutation($uid: String!, $type: TypeChirurgieInput!) {
  updateTypeChirurgie(uid: $uid, type: $type)
}
`
export const DELETE_TYPE_CHIRURGIE = gql`
mutation Mutation($uid: String!) {
  deleteTypeChirurgie(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_CHIRURGIE= gql`
subscription Subscription {
  type_chirurgie {
    type
    data {
      uid
      libelle
      faible
    }
  }
}`


/** CHIRURGIE */
export const ADD_CHIRURGIE = gql`
mutation Mutation($chirurgie: ChirurgieInput!) {
  addChirurgie(chirurgie: $chirurgie)
}
`
export const UPDATE_CHIRURGIE = gql`
mutation Mutation($uid: String!, $chirurgie: ChirurgieInput!) {
  updateChirurgie(uid: $uid, chirurgie: $chirurgie)
}
`
export const DELETE_CHIRURGIE = gql`
mutation Mutation($uid: String!) {
  deleteChirurgie(uid: $uid)
}
`
export const SUBSCRIBE_CHIRURGIE= gql`
subscription Subscription {
  chirurgie {
    type
    data {
      uid
      type
      animal
      hospitalisation
      duration
      info
      createBy
      createdAt
    }
  }
}`



/** MOTIF_CONSULTATION */
export const ADD_MOTIF_CONSULTATION = gql`
mutation Mutation($motif: MotifConsultationInput!) {
  addMotifConsultation(motif: $motif)
}
`
export const UPDATE_MOTIF_CONSULTATION = gql`
mutation Mutation($uid: String!, $motif: MotifConsultationInput!) {
  updateMotifMotifConsultation(uid: $uid, motif: $motif)
    }
`
export const DELETE_MOTIF_CONSULTATION = gql`
mutation Mutation($uid: String!) {
  deleteMotifConsultation(uid: $uid)
}
`
export const SUBSCRIBE_MOTIF_CONSULTATION= gql`
subscription Subscription {
  motif_consultation {
    type
    data {
      uid
      libelle
    }
  }
}`

/** TYPE_PRONOSTIC */
export const ADD_TYPE_PRONOSTIC = gql`
mutation Mutation($type: TypePronosticInput!) {
  addTypePronostic(type: $type)
}
`
export const UPDATE_TYPE_PRONOSTIC = gql`
mutation Mutation($uid: String!, $type: TypePronosticInput!) {
  updateTypePronostic(uid: $uid, type: $type)
    }
`
export const DELETE_TYPE_PRONOSTIC = gql`
mutation Mutation($uid: String!) {
  deleteTypePronostic(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_PRONOSTIC= gql`
subscription Subscription {
  type_pronostic {
    type
    data {
      uid
      libelle
    }
  }
}`


/** PRONOSTIC */
export const ADD_PRONOSTIC = gql`
mutation Mutation($pronostic: PronosticInput!) {
  addPronostic(pronostic: $pronostic)
}
`
export const UPDATE_PRONOSTIC = gql`
mutation Mutation($uid: String!, $pronostic: PronosticInput!) {
  updatePronostic(uid: $uid, pronostic: $pronostic)
    }
`
export const DELETE_PRONOSTIC = gql`
mutation Mutation($uid: String!) {
  deletePronostic(uid: $uid)
}
`
export const SUBSCRIBE_PRONOSTIC= gql`
subscription Subscription {
  pronostic {
    type
    data {
      uid
      animal
      type
      createBy
      createdAt
    }
  }
}`
/** VACCIN */
export const ADD_VACCIN = gql`
mutation Mutation($vaccin: VaccinInput!) {
  addVaccin(vaccin: $vaccin)
}
`
export const UPDATE_VACCIN = gql`
mutation Mutation($uid: String!, $vaccin: VaccinInput!) {
  updateVaccin(uid: $uid, vaccin: $vaccin)
    }
`
export const DELETE_VACCIN = gql`
mutation Mutation($uid: String!) {
  deleteVaccin(uid: $uid)
}
`
export const SUBSCRIBE_VACCIN = gql`
subscription Subscription {
  vaccin {
    type
    data {
      espece
      uid
      libelle
      maladie
      valence
      primo
      annuel
    }
  }
}`

/** PARASITE */
export const ADD_TYPE_PARASITE = gql`
mutation Mutation($type: TypeParasiteInput!) {
  addTypeParasite(type: $type)
}
`
export const UPDATE_TYPE_PARASITE = gql`
mutation Mutation($uid: String!, $type: TypeParasiteInput!) {
  updateTypeParasite(uid: $uid, type: $type)
    }
`
export const DELETE_TYPE_PARASITE = gql`
mutation Mutation($uid: String!) {
  deleteTypeParasite(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_PARASITE = gql`
subscription Subscription {
  type_parasite {
    type
    data {
      uid
      libelle
    }
  }
}`

export const ADD_ANTI_PARASITAIRE = gql`
mutation Mutation($medicament: AntiParasitaireInput!) {
  addAntiParasitaire(medicament: $medicament)
}
`
export const UPDATE_ANTI_PARASITAIRE = gql`
mutation Mutation($uid: String!, $medicament: AntiParasitaireInput!) {
  updateAntiParasitaire(uid: $uid, medicament: $medicament)
    }
`
export const DELETE_ANTI_PARASITAIRE = gql`
mutation Mutation($uid: String!) {
  deleteAntiParasitaire(uid: $uid)
}
`
export const SUBSCRIBE_ANTI_PARASITAIRE = gql`
subscription Subscription {
  anti_parasitaire {
    type
    data {
      uid
      libelle
      type
    }
  }
}`



/** MALADIE */
export const ADD_TYPE_MALADIE = gql`
mutation AddTypeMaladie($type: TypeMaladieInput!) {
  addTypeMaladie(type: $type)
}
`
export const UPDATE_TYPE_MALADIE = gql`
mutation UpdateTypeMaladie($uid: String!, $type: TypeMaladieInput!) {
  updateTypeMaladie(uid: $uid, type: $type)
    }
`
export const DELETE_TYPE_MALADIE = gql`
mutation Mutation($uid: String!) {
  deleteTypeMaladie(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_MALADIE = gql`
subscription Subscription {
  type_maladie {
    type
    data {
      uid
      libelle
    }
  }
}`

export const ADD_MALADIE = gql`
mutation AddMaladie($maladie: MaladieInput!) {
  addMaladie(maladie: $maladie)
}
`
export const UPDATE_MALADIE = gql`
mutation UpdateMaladie($uid: String!, $maladie: MaladieInput!) {
  updateMaladie(uid: $uid, maladie: $maladie)
    }
`
export const DELETE_MALADIE = gql`
mutation Mutation($uid: String!) {
  deleteMaladie(uid: $uid)
}
`
export const SUBSCRIBE_MALADIE = gql`
subscription Subscription {
  maladie {
    type
    data {
      uid
      libelle
      type
    }
  }
}`


/** MEDICAMENT */
export const ADD_FAMILLE_MEDICAMENT = gql`
mutation Mutation($famille: FamilleMedicamentInput!) {
  addFamilleMedicament(famille: $famille)
}
`
export const UPDATE_FAMILLE_MEDICAMENT = gql`
mutation Mutation($uid: String!, $famille: FamilleMedicamentInput!) {
  updateFamilleMedicament(uid: $uid, famille: $famille)
    }
`
export const DELETE_FAMILLE_MEDICAMENT = gql`
mutation Mutation($uid: String!) {
  deleteFamilleMedicament(uid: $uid)
}
`
export const SUBSCRIBE_FAMILLE_MEDICAMENT = gql`
subscription Subscription {
  famille_medicament {
    type
    data {
      uid
      libelle
    }
  }
}`

export const ADD_MEDICAMENT = gql`
mutation Mutation($medicament: MedicamentInput!) {
  addMedicament(medicament: $medicament)
}
`
export const UPDATE_MEDICAMENT = gql`
mutation Mutation($uid: String!, $medicament: MedicamentInput!) {
  updateMedicament(uid: $uid, medicament: $medicament)
    }
`
export const DELETE_MEDICAMENT = gql`
mutation Mutation($uid: String!) {
  deleteMedicament(uid: $uid)
}
`
export const SUBSCRIBE_MEDICAMENT = gql`
subscription Subscription {
  medicament {
    type
    data {
      uid
      libelle
      famille
    }
  }
}`


/** TYPE ANALYSE */
export const ADD_TYPE_ANALYSE = gql`
mutation AddTypeAnalyse($type: TypeAnalyseInput) {
  addTypeAnalyse(type: $type)
}
`
export const UPDATE_TYPE_ANALYSE = gql`
mutation UpdateTypeAnalyse($uid: String!, $type: TypeAnalyseInput) {
    updateTypeAnalyse(uid: $uid, type: $type)
    }
`
export const DELETE_TYPE_ANALYSE = gql`
mutation Mutation($uid: String!) {
  deleteTypeAnalyse(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_ANALYSE = gql`
subscription Subscription {
  type_analyse {
    type
    data {
      uid
      libelle
      hasCategorie
    }
  }
}`

/** CATEGORIE ANALYSE */
export const ADD_CATEGORIE_ANALYSE = gql`
mutation addCategorieAnalyse($categorie: CategorieAnalyseInput) {
  addCategorieAnalyse(categorie: $categorie)
}
`
export const UPDATE_CATEGORIE_ANALYSE = gql`
mutation updateCategorieAnalyse($uid: String!, $categorie: CategorieAnalyseInput) {
  updateCategorieAnalyse(uid: $uid, categorie: $categorie)
    }
`
export const DELETE_CATEGORIE_ANALYSE = gql`
mutation Mutation($uid: String!) {
  deleteCategorieAnalyse(uid: $uid)
}
`
export const SUBSCRIBE_CATEGORIE_ANALYSE = gql`
subscription Subscription {
  categorie_analyse {
    type
    data {
      uid
      libelle
      hasRecherche
      typeAnalyse
    }
  }
}`


/** TYPE RECHERCHE */
export const ADD_TYPE_RECHERCHE = gql`
mutation addTypeRecherche($type: TypeRechercheInput) {
  addTypeRecherche(type: $type)
}
`
export const UPDATE_TYPE_RECHERCHE = gql`
mutation updateTypeRecherche($uid: String!, $type: TypeRechercheInput) {
  updateTypeRecherche(uid: $uid, type: $type)
    }
`
export const DELETE_TYPE_RECHERCHE = gql`
mutation Mutation($uid: String!) {
  deleteTypeRecherche(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_RECHERCHE = gql`
subscription Subscription {
  type_recherche {
    type
    data {
      uid
      libelle
      hasExamen
      categorieAnalyse
      hasFiche
      fiche
    }
  }
}`


export const ADD_EXAMEN = gql`
mutation addExamen($examen: ExamenInput) {
  addExamen(examen: $examen)
}
`
export const UPDATE_EXAMEN = gql`
mutation updateExamen($uid: String!, $examen: ExamenInput) {
  updateExamen(uid: $uid, examen: $examen)
    }
`
export const DELETE_EXAMEN = gql`
mutation Mutation($uid: String!) {
  deleteExamen(uid: $uid)
}
`
export const SUBSCRIBE_EXAMEN = gql`
subscription Subscription {
  examen {
    type
    data {
      uid
      libelle
      montant
      typeRecherche
      hasFiche
      fiche
    }
  }
}`




/** ANAMNESE */

export const ADD_ANAMNESE = gql`
mutation AddAnamnese($anamnese: AnamneseInput) {
  addAnamnese(anamnese: $anamnese)
}
`

export const UPDATE_ANAMNESE = gql`
mutation UpdateAnamnese($uid: String!, $anamnese: AnamneseInput) {
        updateAnamnese(uid: $uid, anamnese: $anamnese)
    }
`
export const DELETE_ANAMNESE = gql`
mutation Mutation($uid: String!) {
  deleteAnamnese(uid: $uid)
}
`
export const SUBSCRIBE_ANAMNESE = gql`
subscription Subscription {
  anamnese {
    type
    data {
      uid
      animal
      vaccine
      vermifuge
      motif
      duration
      symptome
      traitement
      antecedant
      alimentation
      modeVie
      createBy
      operationDate
    }
  }
}
`


/** EXAMEN CLINIQUE */
export const ADD_EXAMEN_CLINIQUE = gql`
mutation Mutation($examen: ExamenCliniqueInput) {
  addExamenClinique(examen: $examen)
}
`

export const UPDATE_EXAMEN_CLINIQUE = gql`
mutation Mutation($uid: String!, $examen: ExamenCliniqueInput) {
  updateExamenClinique(uid: $uid, examen: $examen)
}
`

export const DELETE_EXAMEN_CLINIQUE = gql`
mutation UpdateQuestion($uid: String!) {
  deleteExamenClinique(uid: $uid)
}
`

export const SUBSCRIBE_EXAMEN_CLINIQUE = gql`
subscription Subscription {
  examen_clinique {
    type
    data {
      uid
      animal
      createBy
      detail
      createdAt
      anamnese
      analyseDif
    }
  }
}
`

/** VACCINATION */
export const ADD_VACCINATION = gql`
mutation Mutation($vaccination: VaccinationInput!, $poly: [PolyVaccinInput!]!) {
  addVaccination(vaccination: $vaccination, poly: $poly)
}`

export const UPDATE_VACCINATION = gql`
mutation Mutation($uid: String!, $vaccination: VaccinationInput!) {
  updateVaccination(uid: $uid, vaccination: $vaccination)
}
`
export const DELETE_VACCINATION = gql`
mutation Mutation($uid: String!) {
  deleteVaccination(uid: $uid)
}
`

export const SUBSCRIBE_VACCINATION = gql`
subscription Subscription {
  vaccination {
    type
    data {
      uid
      animal
      createBy
      createdAt
      maladie
      vaccin
      info
    }
  }
}
`

/** TRAITEMENT */
export const ADD_TRAITEMENT = gql`
mutation Mutation($traitement: TraitementInput!) {
  addTraitement(traitement: $traitement)
}`

export const UPDATE_TRAITEMENT = gql`
mutation Mutation($uid: String!, $traitement: TraitementInput!) {
  updateTraitement(uid: $uid, traitement: $traitement)
}
`
export const DELETE_TRAITEMENT = gql`
mutation Mutation($uid: String!) {
  deleteTraitement(uid: $uid)
}
`

export const SUBSCRIBE_TRAITEMENT = gql`
subscription Subscription {
  traitement {
    type
    data {
      uid
      animal
      createBy
      createdAt
      maladie
      medicaments
      info
    }
  }
}
`


/** ANALYSE */
export const ADD_ANALYSE = gql`
mutation Mutation($analyse: AnalyseInput) {
  addAnalyse(analyse: $analyse)
}`

export const UPDATE_ANALYSE = gql`
mutation Mutation($uid: String!, $analyse: AnalyseInput) {
  updateAnalyse(uid: $uid, analyse: $analyse)
}
`
export const DELETE_ANALYSE = gql`
mutation Mutation($uid: String!) {
  deleteAnalyse(uid: $uid)
}
`

export const SUBSCRIBE_ANALYSE = gql`
subscription Subscription {
  analyse {
    type
    data {
      uid
      animal
      createBy
      type
      categorie
      createdAt
      typeRecherche
      examen
      infoSupplementaire
      operationDate
    }
  }
}
`


/** RESULTAT D'ANALYSE */
export const ADD_RESULTAT_ANALYSE = gql`
mutation Mutation($resultat: ResultatAnalyseInput) {
  addResultatAnalyse(resultat: $resultat)
}
`

export const DELETE_RESULTAT_ANALYSE = gql`
mutation Mutation($uid: String!) {
  deleteResultatAnalyse(uid: $uid)
}
`
export const SUBSCRIBE_RESULTAT_ANALYSE = gql`
subscription Subscription {
  resultat_analyse {
    type
    data {
      uid
      analyse
      createBy
      createdAt
      conclusion
      files
    }
  }
}
`
/**SECTION */
export const ADD_SECTION = gql`
mutation Mutation($section: SectionInput) {
  addSection(section: $section)
}
`
export const UPDATE_SECTION = gql`
mutation Mutation($uid: String!, $section: SectionInput) {
  updateSection(uid: $uid, section: $section)
}
`
export const DELETE_SECTION = gql`
mutation Mutation($uid: String!) {
  deleteSection(uid: $uid)
}
`
export const SUBSCRIBE_SECTION= gql`
subscription Subscription {
  section {
    type
    data {
      uid
      libelle
    }
  }
}`

/* QUESTION */
export const ADD_QUESTION = gql`
mutation Mutation($question: QuestionInput) {
  addQuestion(question: $question)
}
`
export const UPDATE_QUESTION = gql`
mutation Mutation($uid: String!, $question: QuestionInput) {
  updateQuestion(uid: $uid, question: $question)
}
`
export const DELETE_QUESTION = gql`
mutation Mutation($uid: String!) {
  deleteQuestion(uid: $uid)
}
`
export const SUBSCRIBE_QUESTION= gql`
subscription Subscription {
  question {
    type
    data {
      uid
      libelle
      ordre
      section
      code
      multiResponse
      libre
      sexe
    }
  }
}`


/* QUESTION */
export const ADD_RESPONSE = gql`
mutation Mutation($response: ResponseInput) {
  addResponse(response: $response)
}
`
export const UPDATE_RESPONSE = gql`
mutation Mutation($uid: String!, $response: ResponseInput) {
  updateResponse(uid: $uid, response: $response)
}
`
export const DELETE_RESPONSE = gql`
mutation Mutation($uid: String!) {
  deleteResponse(uid: $uid)
}
`
export const SUBSCRIBE_RESPONSE= gql`
subscription Subscription {
  response {
    type
    data {
      uid
      libelle
      ordre
      question
    }
  }
}`

export const ADD_TOILETTAGE_ACTIVITY = gql`
mutation Mutation($activity: ToilettageActivityInput!) {
  addToilettageActivity(activity: $activity)
}`
export const UPDATE_TOILETTAGE_ACTIVITY = gql`
mutation Mutation($uid: String!, $activity: ToilettageActivityInput!) {
  updateToilettageActivity(uid: $uid, activity: $activity)
}`
export const DELETE_TOILETTAGE_ACTIVITY = gql`
mutation Mutation($uid: String!) {
  deleteToilettageActivity(uid: $uid)
}`
export const SUBSCRIBE_TOILETTAGE_ACTIVITY = gql`
subscription Subscription {
  toilettage_activity {
    type
    data {
      uid
      libelle
      prix
      currency
    }
  }
}`

export const ADD_TOILETTAGE = gql`
mutation Mutation($toilettage: ToilettageInput!) {
  addToilettage(toilettage: $toilettage)
}
`
export const UPDATE_TOILETTAGE = gql`
mutation Mutation($uid: String!, $toilettage: ToilettageInput!) {
  updateToilettage(uid: $uid, toilettage: $toilettage)
}
`
export const DELETE_TOILETTAGE = gql`
mutation Mutation($uid: String!) {
  deleteToilettage(uid: $uid)
}
`
export const SUBSCRIBE_TOILETTAGE = gql`
subscription Subscription {
  toilettage {
    type
    data {
      uid
      animal
      activities
      info
      createBy
      createdAt
      dateToilettage
      dateRappel
      prix
      currency
    }
  }
}`