<template>
  <div>
    <div
      v-if="$apollo.queries.init.loading"
      class="loading-data"
    >
      <div class="loading-container">
        <div class="circle-one" />
        <div class="loading-text">
          <p>Chargement des données utilisateurs ...</p>
          <small>Patientez!</small>
        </div>
      </div>
    </div>
    <tour-tutorial v-if="showTour" />
    <!--  Navbar Starts  -->
    <div class="header-container fixed-top">
      <theme-header />
    </div>
    <!--  Navbar Ends  -->
    <!--  Main Container Starts  -->
    <div
      class="main-container"
      id="container"
    >
      <div class="overlay" />
      <div class="search-overlay" />
      <div class="rightbar-overlay" />
      <!--  Sidebar Starts  -->
      <div class="sidebar-wrapper sidebar-theme">
        <theme-menu />
      </div>
      <!--  Sidebar Ends  -->
      <!--  Content Area Starts  -->
      <div
        id="content"
        class="main-content"
      >
        <!-- Main Body Starts -->
        <div class="layout-px-spacing">
          <!-- <div class="row layout-top-spacing"> 
            <div class="col-md-12"> -->
          <!-- <div class="row"> -->
          <!-- <div class="container p-0"> -->
          <!-- <div class="row layout-top-spacing"> -->
          <!-- <div class="col-lg-12 layout-spacing"> -->
          <router-view />
          <!-- </div> -->
          <!-- </div> -->
          <!-- </div> -->
          <!-- </div>
                            </div> -->
          <!-- </div> -->
        </div>
        <!-- Main Body Ends -->
        <!-- <div class="responsive-msg-component">
                    <p>
                        <a class="close-msg-component"><i class="las la-times"></i></a>
                        Please reload the page to view the responsive functionalities
                    </p>
                </div> -->
        <!-- Switch Account information start-->
        <div
          class="switch-account"
          v-if="switchAccount"
        >
          ceci est un test
        </div>
        <!-- Switch Account information end-->
        <!-- Copyright Footer Starts -->
        <div class="footer-wrapper">
          <div class="footer-section f-section-1">
            <p class="">
              Copyright © 2023 <a
                href="javascript:void(0)"
                id="forteckLink"
              >Groupe AGRO-EXPERTISE</a>, All rights reserved.
            </p>
          </div>
          <div class="footer-section f-section-2">
            <p class="">
              Crafted with extra <i class="las la-heart text-danger" />
            </p>
          </div>
        </div>
        <!-- Copyright Footer Ends -->
        <!-- Arrow Starts -->
        <div
          class="scroll-top-arrow"
          style="display: none;"
        >
          <i class="las la-angle-up" />
        </div>
        <!-- Arrow Ends -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import ThemeHeader from './themeHeader.vue'
import ThemeMenu from './themeMenu.vue'
import tourTutorial from '../tourTutorial.vue'
import { App } from '../../assets/js/app'
import { ADD, UPDATE, DELETE } from '../../constants/app'
import { INIT_DATA } from '../../graphql'
import { SUBSCRIBER_TYPE_COMPTE, SUBSCRIBER_USER, SUBSCRIBE_DIRECT_SMS } from '../../graphql/user'
import { SUBSCRIBER_GROUPE, SUBSCRIBER_GROUP_PARAM } from '../../graphql/group'
export default {
    components: { ThemeHeader, ThemeMenu, tourTutorial },
    mounted(){
        App.init()
    },
    data(){
        return {
            me: null,
            showTour: false,
        }
    },
    
    watch: {
        
        
    },
    apollo: {
      $subscribe: {
        users: {
            query: SUBSCRIBER_USER ,
            result({data}){
                let u = data.users
                if(u.type === ADD) this.addUser(u.data)
                if(u.type === DELETE) this.deleteUser(u.data)
                if(u.type === UPDATE) this.updateUser(u.data)
            }
        },
        groupe: {
          query: SUBSCRIBER_GROUPE ,
            result({data}){
                let u = data.groupe
                if(u.type === ADD) this.addGroup(u.data)
                if(u.type === DELETE) this.deleteGroup(u.data)
                if(u.type === UPDATE) this.updateGroup(u.data)
            }
        },
        group_param: {
          query: SUBSCRIBER_GROUP_PARAM ,
            result({data}){
                let u = data.group_param
                if(u.type === ADD) this.addGroupParam(u.data)
                if(u.type === DELETE) this.deleteGroupParam(u.data)
            }
        },
        type_compte: {
            query: SUBSCRIBER_TYPE_COMPTE ,
            result({data}){
                let t = data.type_compte
                if(t.type === ADD) this.addTypeCompte(t.data)
                if(t.type === DELETE) this.deleteTypeCompte(t.data)
                if(t.type === UPDATE) this.updateTypeCompte(t.data)
            }
        },
        direct_sms: {
            query: SUBSCRIBE_DIRECT_SMS ,
            result({data}){
                let m = data.direct_sms
                if(m.type === ADD) this.addDirectSms(m.data)
                if(m.type === DELETE) this.deleteDiretSms(m.data)
            }
        },
      },
      init: {
          query: INIT_DATA,
          update (data) {
            // console.log(data)
            this.me = data.me
            this.setMe(data.me)
            this.setUsers(data.users)
            this.setGroups(data.groupes)
            this.setGroupParams(data.groupeParams)
            this.setEspeces(data.especes)
            this.setRobes(data.robes)
            this.setTypeComptes(data.typeComptes)
            this.setRaces(data.races)
            this.setTypeRobes(data.typeRobes)
            this.setAnimaux(data.animaux)
            this.setAliments(data.alimentations)
            this.setModeVies(data.modevies)
            this.setQuestions(data.questions)
            this.setSections(data.sections)
            this.setResponses(data.responses)
            this.setAnamneses(data.anamneses)
            this.setExamenCliniques(data.examencliniques)

            this.setTypeAnalyses(data.typeAnalyses)
            this.setCategorieAnalyses(data.categorieAnalyses)
            this.setTypeRecherches(data.typeRecherches)
            this.setExamens(data.examens)

            this.setAnalyses(data.analyses)
            this.setFichierResultatAnalyses(data.resultatAnalyses)
            this.setTypeMaladies(data.typeMaladies)
            this.setMaladies(data.maladies)
            this.setFamilleMedicaments(data.familleMedicaments)
            this.setMedicaments(data.medicaments)
            this.setVaccins(data.vaccins)
            this.setAntiParasitaires(data.antiParasitaires)
            this.setTypeParasites(data.typeParasites)
            this.setVaccinations(data.vaccinations)
            this.setTraitements(data.traitements)
            this.setTypePronostics(data.typePronostics)
            this.setPronostics(data.pronostics)
            this.setConstantes(data.constantes)
            this.setSms(data.sms)
            this.setDirectSms(data.directSms)
            this.setMotifConsultations(data.motifConsultations)
            this.setTypeChirugies(data.typeChirurgies)
            this.setChirurgies(data.chirurgies)
            this.setFicheAnalyses(data.ficheAnalyses)
            this.setFicheSections(data.sectionFiches)
            this.setFicheParametres(data.parametreFiches)
            this.setFicheReferences(data.referenceFiches)
            this.setTypeFicheAnalyses(data.typeFicheAnalyses)
            this.setResultatAnalyses(data.resultatAnalyseFiches)
            this.setToilettageActivities(data.toilettageActivities)
            this.setToilettages(data.toilettages)
            this.setMotifDeces(data.motifDeces)
            this.setActionsPostMortem(data.actionsPostMortem)
            this.setDeclarationDeces(data.declarationDeces)
            this.setTypesEvent(data.typeEvents)
            this.setEvents(data.events)
            this.setNotifications(data.notifications)
            // this.setParameters(data.parameters)
            // this.salles.forEach(item => {
            //     this.updateAlertList(item)
            // })

            this.showTour = true
          }
      }
        
    },
    methods: {
        ...mapMutations({
            setMe: 'auth/SET_ME',
            setUsers: 'auth/SET_USERS',
            addUser: 'auth/ADD_USER',
            updateUser: 'auth/UPDATE_USER',
            deleteUser: 'auth/DELETE_USER',
            setGroups: 'group/SET_GROUPS',
            setGroupParams: 'group/SET_PARAMS',
            addGroup: 'group/ADD_GROUP',
            updateGroup: 'group/UPDATE_GROUP',
            deleteGroup: 'group/DELETE_GROUP',
            addGroupParam: 'group/ADD_PARAM',
            deleteGroupParam: 'group/DELETE_PARAM',
            setPresences: 'auth/SET_PRESENCES',
            addPresence: 'auth/ADD_PRESENCE',
            updatePresence: 'auth/UPDATE_PRESENCE',
            setTypeComptes: 'auth/SET_TYPE_COMPTES',
            addTypeCompte: 'auth/ADD_TYPE_COMPTE',
            updateTypeCompte: 'auth/UPDATE_TYPE_COMPTE',
            deleteTypeCompte: 'auth/DELETE_TYPE_COMPTE',
            setPays: 'auth/SET_PAYS',
            
            setAnimaux: 'identification/SET_ANIMAUX',
            setEspeces: 'identification/SET_ESPECES',
            setRaces: 'identification/SET_RACES',
            setRobes: 'identification/SET_ROBES',
            setTypeRobes: 'identification/SET_TYPE_ROBES',
            setAliments: 'identification/SET_ALIMENTS',
            setModeVies: 'sanitaire/SET_MODE_VIES',
            setSections: 'sanitaire/SET_SECTIONS',
            setQuestions: 'sanitaire/SET_QUESTIONS',
            setResponses: 'sanitaire/SET_RESPONSES',
            setAnamneses: 'sanitaire/SET_ANAMNESES',
            setExamenCliniques: 'sanitaire/SET_EXAMEN_CLINIQUES',
            setTypeAnalyses: 'sanitaire/SET_TYPE_ANALYSES',
            setCategorieAnalyses: 'sanitaire/SET_CATEGORIE_ANALYSES',
            setTypeRecherches:'sanitaire/SET_TYPE_RECHERCHES',
            setExamens: 'sanitaire/SET_EXAMENS',
            setAnalyses: 'sanitaire/SET_ANALYSES',
            setFichierResultatAnalyses: 'sanitaire/SET_RESULTAT_ANALYSES',
            setTypeMaladies:'sanitaire/SET_TYPE_MALADIES',
            setMaladies: 'sanitaire/SET_MALADIES',
            setFamilleMedicaments: 'sanitaire/SET_FAMILLE_MEDICAMENTS',
            setMedicaments: 'sanitaire/SET_MEDICAMENTS',
            setVaccins: 'sanitaire/SET_VACCINS',
            setAntiParasitaires: 'sanitaire/SET_ANTI_PARASITAIRES',
            setTypeParasites: 'sanitaire/SET_TYPE_PARASITES',
            setVaccinations: 'sanitaire/SET_VACCINATIONS',
            setTraitements: 'sanitaire/SET_TRAITEMENTS',
            setTypePronostics: 'sanitaire/SET_TYPE_PRONOSTICS',
            setPronostics: 'sanitaire/SET_PRONOSTICS',
            setConstantes: 'identification/SET_CONSTANTES',
            setSms: 'auth/SET_SMS',
            setDirectSms: 'auth/SET_DIRECT_SMS',
            addDirectSms: 'auth/ADD_DIRECT_SMS',
            deleteDiretSms: 'auth/DELETE_DIRECT_SMS',
            setMotifConsultations: 'sanitaire/SET_MOTIF_CONSULTATIONS',
            setTypeChirugies: 'sanitaire/SET_TYPE_CHIRURGIES',
            setChirurgies: 'sanitaire/SET_CHIRURGIES',

            setFicheAnalyses: 'sanitaire/SET_FICHE_ANALYSES',
            setTypeFicheAnalyses: 'sanitaire/SET_TYPE_FICHE_ANALYSES',
            setFicheSections: 'sanitaire/SET_FICHE_SECTIONS',
            setFicheParametres: 'sanitaire/SET_FICHE_PARAMETRES',
            setFicheReferences: 'sanitaire/SET_FICHE_REFERENCES',

            setResultatAnalyses: 'analyse/SET_RESULTAT_ANALYSES',
            // Parameter
            setParameters: 'metier/SET_PARAMETERS',
            updateParameter: 'metier/UPDATE_PARAMETER',


            setToilettageActivities: 'sanitaire/SET_TOILETTAGE_ACTIVITIES',
            setToilettages: 'sanitaire/SET_TOILETTAGES',

            setActionsPostMortem: 'identification/SET_ACTIONS_POST_MORTEM',
            setMotifDeces: 'identification/SET_MOTIF_DECES',
            setDeclarationDeces: 'identification/SET_DECLARATION_DECES',

            setTypesEvent: 'events/SET_TYPES',
            setEvents: 'events/SET_EVENTS',
            setNotifications: 'notification/SET_NOTIFICATIONS',

            //  audio
            addAudio: 'metier/ADD_AUDIO',
            removeAudio: 'metier/REMOVE_AUDIO',
            addSalleAlert: 'metier/ADD_SALLE_ALERT',
            removeSalleAlert: 'metier/REMOVE_SALLE_ALERT',
            changeState: 'metier/UPDATE_STATE'
        }),
        
        
    },
    computed: {
        ...mapGetters({ 
          switchAccount: 'auth/switchAccount'
        })
    }
    
}
</script>
<style lang="scss" scoped>

.switch-account{
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  margin-left: -20px;
  background-color: #BB1B1B;
  color: white;
}
.loading-data{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0,0,0,0.75);
  .loading-text{
    position: absolute;
    width: 100%;
    top: 55%;
    text-align: center;
    color: white;
    p{
      color: white;
    }
  }
}
.circle-one {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 4px rgba(#1C6100, 0.25) solid;
  border-top: 4px #B50404 solid;
  border-bottom: 4px #B50404 solid;
  border-radius: 50%;
  -webkit-animation: circleone 1s infinite linear;
  animation: circleone 1s infinite linear;
}
@-webkit-keyframes circleone {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes circleone {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>