import Vue from 'vue'
import {ApolloClient} from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

import VueApollo from 'vue-apollo'

import {GRAPHQL_API_ROOT, WS_ROOT} from '../constants/app'
import { login } from '../plugins/authService'
import errorLink from './errorHandler'
Vue.use(VueApollo)

const getHeaders = () => {
    const headers = {}
    const token = login()
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    return headers
  }

const httpLink = new HttpLink({
    uri: GRAPHQL_API_ROOT,
    fetch,
    headers: getHeaders()
})

const wsLink = new WebSocketLink({
  uri: WS_ROOT,
  options: {
    reconnect: true
  }
})

const link = split(
    ({query}) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    },
    // wsErrorHandler.concat(wsLink),
    wsLink,
    errorLink.concat(httpLink),
    
    // httpLink,
    // errorLink
)
const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    }
  })
  
  export default apolloProvider
