import moment from 'moment'

const state = {
    notifications: []
}

const getters = {
    notifications: state => state.notifications,
    unreadNotifications: state => state.notifications.filter(item => !item.read).sort((b,a) => moment(a.createdAT).format('X') - moment(b.createdAT).format('X')),
    hasUnreadNotification: state => state.notifications.filter(item => !item.read).length
}


const mutations = {
    SET_NOTIFICATIONS: (state, list) => { state.notifications = list },
    ADD_NOTIFICATION: (state, notification) => {state.notifications.push(notification)},
    UPDATE_NOTIFICATION: (state, notification) => {state.notifications = state.notifications.filter(item => item.uid !== notification.uid); state.notifications.push(notification)},
    DELETE_NOTIFICATION: (state, notification) => {state.notifications = state.notifications.filter(item => item.uid !== notification.uid)}
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
}