<template>
  <div>
    <li class="menu">
      <router-link
        :to="{name: 'vaccination'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-id-card" />
          <span>Vaccination</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'anamnese'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-hospital" />
          <span>Anamnèse</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'constant'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-stethoscope" />
          <span>Constantes</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'examen_clinique'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-heartbeat" />
          <span>Examen clinique</span>
        </div>
      </router-link>
    </li>
    <li
      class="menu"
      id="menu-examen-para-clinique"
    >
      <router-link
        :to="{name: 'analyses'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-flask" />
          <span>Examen Para-clinique</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'traitement'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-prescription" />
          <span>Traitement</span>
        </div>
      </router-link>
    </li>

    <li class="menu">
      <router-link
        :to="{name: 'toilettage'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-bath" />
          <span>Toilettage</span>
        </div>
      </router-link>
    </li>

    <li class="menu">
      <router-link
        :to="{name: 'chirurgie'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-cut" />
          <span>Chirurgie</span>
        </div>
      </router-link>
    </li>

    <li class="menu">
      <router-link
        :to="{name: 'pronostic'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-id-badge" />
          <span>Pronostic</span>
        </div>
      </router-link>
    </li>

    <li class="menu">
      <router-link
        :to="{name: 'sa_export'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-file-export" />
          <span>Export Data</span>
        </div>
      </router-link>
    </li>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { ADD, DELETE, UPDATE } from '../../../constants/app'
import { SUBSCRIBE_FICHE_ANAYLSE, SUBSCRIBE_PARAMETRE_FICHE, SUBSCRIBE_REFERENCE_FICHE, SUBSCRIBE_RESULTAT_ANALYSE_FICHE, SUBSCRIBE_SECTION_FICHE, SUBSCRIBE_TYPE_FICHE_ANAYLSE } from '../../../graphql/ficheAnalyse'
import { 
    SUBSCRIBE_TYPE_ANALYSE, SUBSCRIBE_ANALYSE, SUBSCRIBE_ANAMNESE, 
    SUBSCRIBE_EXAMEN_CLINIQUE, SUBSCRIBE_RESULTAT_ANALYSE, SUBSCRIBE_CATEGORIE_ANALYSE, 
    SUBSCRIBE_TYPE_RECHERCHE, SUBSCRIBE_EXAMEN, SUBSCRIBE_TYPE_MALADIE, 
    SUBSCRIBE_MALADIE, 
    SUBSCRIBE_FAMILLE_MEDICAMENT,
    SUBSCRIBE_MEDICAMENT,
    SUBSCRIBE_VACCIN,
    SUBSCRIBE_TYPE_PARASITE,
    SUBSCRIBE_ANTI_PARASITAIRE,
    SUBSCRIBE_VACCINATION,
    SUBSCRIBE_TRAITEMENT,
    SUBSCRIBE_TYPE_PRONOSTIC,
    SUBSCRIBE_PRONOSTIC,
    SUBSCRIBE_MOTIF_CONSULTATION,
    SUBSCRIBE_TYPE_CHIRURGIE,
    SUBSCRIBE_CHIRURGIE,
    SUBSCRIBE_SECTION,
    SUBSCRIBE_MODE_VIE,
    SUBSCRIBE_QUESTION,
    SUBSCRIBE_RESPONSE,
    SUBSCRIBE_TOILETTAGE_ACTIVITY,
    SUBSCRIBE_TOILETTAGE
} from '../../../graphql/sanitaire'
export default {
    data(){
        return {

        }
    },
    methods: {
        ...mapMutations({
            addModeVie: 'sanitaire/ADD_MODE_VIE',
            updateModeVie: 'sanitaire/UPDATE_MODE_VIE',
            deleteModeVie: 'sanitaire/DELETE_MODE_VIE',

            addSection: 'sanitaire/ADD_SECTION',
            updateSection: 'sanitaire/UPDATE_SECTION',
            deleteSection: 'sanitaire/DELETE_SECTION',

            addQuestion: 'sanitaire/ADD_QUESTION',
            updateQuestion: 'sanitaire/UPDATE_QUESTION',
            deleteQuestion: 'sanitaire/DELETE_QUESTION',

            addResponse: 'sanitaire/ADD_RESPONSE',
            updateResponse: 'sanitaire/UPDATE_RESPONSE',
            deleteResponse: 'sanitaire/DELETE_RESPONSE',

            addAnalyse: 'sanitaire/ADD_ANALYSE',
            updateAnalyse: 'sanitaire/UPDATE_ANALYSE',
            deleteAnalyse: 'sanitaire/DELETE_ANALYSE',
            
            addResultat: 'sanitaire/ADD_RESULTAT_ANALYSE',
            deleteResultat: 'sanitaire/DELETE_RESULTAT_ANALYSE',
            
            addAnamnese: 'sanitaire/ADD_ANAMNESE',
            updateAnamnese: 'sanitaire/UPDATE_ANAMNESE',
            deleteAnamnese: 'sanitaire/DELETE_ANAMNESE',
            
            addExamenClinique: 'sanitaire/ADD_EXAMEN_CLINIQUE',
            updateExamenClinique: 'sanitaire/UPDATE_EXAMEN_CLINIQUE',
            deleteExamenClinique: 'sanitaire/DELETE_EXAMEN_CLINIQUE',
            
            addTypeAnalyse: 'sanitaire/ADD_TYPE_ANALYSE',
            updateTypeAnalyse: 'sanitaire/UPDATE_TYPE_ANALYSE',
            deleteTypeAnalyse: 'sanitaire/DELETE_TYPE_ANALYSE',
            
            addCategorieAnalyse: 'sanitaire/ADD_CATEGORIE_ANALYSE',
            updateCategorieAnalyse: 'sanitaire/UPDATE_CATEGORIE_ANALYSE',
            deleteCategorieAnalyse: 'sanitaire/DELETE_CATEGORIE_ANALYSE',
            
            addTypeRecherche: 'sanitaire/ADD_TYPE_RECHERCHE',
            updateTypeRecherche: 'sanitaire/UPDATE_TYPE_RECHERCHE',
            deleteTypeRecherche: 'sanitaire/DELETE_TYPE_RECHERCHE',
            
            addExamen: 'sanitaire/ADD_EXAMEN',
            updateExamen: 'sanitaire/UPDATE_EXAMEN',
            deleteExamen: 'sanitaire/DELETE_EXAMEN',
            
            addTypeMaladie: 'sanitaire/ADD_TYPE_MALADIE',
            updateTypeMaladie: 'sanitaire/UPDATE_TYPE_MALADIE',
            deleteTypeMaladie: 'sanitaire/DELETE_TYPE_MALADIE',
            
            addMaladie: 'sanitaire/ADD_MALADIE',
            updateMaladie: 'sanitaire/UPDATE_MALADIE',
            deleteMaladie: 'sanitaire/DELETE_MALADIE',

            addMedicament: 'sanitaire/ADD_MEDICAMENT',
            updateMedicament: 'sanitaire/UPDATE_MEDICAMENT',
            deleteMedicament: 'sanitaire/DELETE_MEDICAMENT',

            addFamilleMedicament: 'sanitaire/ADD_FAMILLE_MEDICAMENT',
            updateFamilleMedicament: 'sanitaire/UPDATE_FAMILLE_MEDICAMENT',
            deleteFamilleMedicament: 'sanitaire/DELETE_FAMILLE_MEDICAMENT',
            
            addVaccin: 'sanitaire/ADD_VACCIN',
            updateVaccin: 'sanitaire/UPDATE_VACCIN',
            deleteVaccin: 'sanitaire/DELETE_VACCIN',

            addTypeParasite: 'sanitaire/ADD_TYPE_PARASITE',
            updateTypeParasite: 'sanitaire/UPDATE_TYPE_PARASITE',
            deleteTypeParasite: 'sanitaire/DELETE_TYPE_PARASITE',

            addAntiParasitaire: 'sanitaire/ADD_ANTI_PARASITAIRE',
            updateAntiParasitaire: 'sanitaire/UPDATE_ANTI_PARASITAIRE',
            deleteAntiParasitaire: 'sanitaire/DELETE_ANTI_PARASITAIRE',

            addVaccination: 'sanitaire/ADD_VACCINATION',
            updateVaccination: 'sanitaire/UPDATE_VACCINATION',
            deleteVaccination: 'sanitaire/DELETE_VACCINATION',

            addTraitement: 'sanitaire/ADD_TRAITEMENT',
            updateTraitement: 'sanitaire/UPDATE_TRAITEMENT',
            deleteTraitement: 'sanitaire/DELETE_TRAITEMENT',

            addTypePronostic: 'sanitaire/ADD_TYPE_PRONOSTIC',
            updateTypePronostic: 'sanitaire/UPDATE_TYPE_PRONOSTIC',
            deleteTypePronostic: 'sanitaire/DELETE_TYPE_PRONOSTIC',

            addPronostic: 'sanitaire/ADD_PRONOSTIC',
            updatePronostic: 'sanitaire/UPDATE_PRONOSTIC',
            deletePronostic: 'sanitaire/DELETE_PRONOSTIC',

            addMotifConsultation: 'sanitaire/ADD_MOTIF_CONSULTATION',
            updateMotifConsultation: 'sanitaire/UPDATE_MOTIF_CONSULTATION',
            deleteMotifConsultation: 'sanitaire/DELETE_MOTIF_CONSULTATION',

            addTypeChirurgie: 'sanitaire/ADD_TYPE_CHIRURGIE',
            updateTypeChirurgie: 'sanitaire/UPDATE_TYPE_CHIRURGIE',
            deleteTypeChirurgie: 'sanitaire/DELETE_TYPE_CHIRURGIE',

            addChirurgie: 'sanitaire/ADD_CHIRURGIE',
            updateChirurgie: 'sanitaire/UPDATE_CHIRURGIE',
            deleteChirurgie: 'sanitaire/DELETE_CHIRURGIE',

            addFicheAnalyse: 'sanitaire/ADD_FICHE_ANALYSE',
            updateFicheAnalyse: 'sanitaire/UPDATE_FICHE_ANALYSE',
            deleteFicheAnalyse: 'sanitaire/DELETE_FICHE_ANALYSE',

            addTypeFicheAnalyse: 'sanitaire/ADD_TYPE_FICHE_ANALYSE',
            updateTypeFicheAnalyse: 'sanitaire/UPDATE_TYPE_FICHE_ANALYSE',
            deleteTypeFicheAnalyse: 'sanitaire/DELETE_FICHE_ANALYSE',

            addFicheSection: 'sanitaire/ADD_FICHE_SECTION',
            updateFicheSection: 'sanitaire/UPDATE_FICHE_SECTION',
            deleteFicheSection: 'sanitaire/DELETE_FICHE_SECTION',

            addFicheParametre: 'sanitaire/ADD_FICHE_PARAMETRE',
            updateFicheParametre: 'sanitaire/UPDATE_FICHE_PARAMETRE',
            deleteFicheParametre: 'sanitaire/DELETE_FICHE_PARAMETRE',

            addFicheReference: 'sanitaire/ADD_FICHE_REFERENCE',
            updateFicheReference: 'sanitaire/UPDATE_FICHE_REFERENCE',
            deleteFicheReference: 'sanitaire/DELETE_FICHE_REFERENCE',

            addResultatAnalyseFiche: 'analyse/ADD_RESULTAT_ANALYSE',
            updateResultatAnalyseFiche:'analyse/UPDATE_RESULTAT_ANALYSE',
            deleteResultatAnalyseFiche: 'analyse/DELETE_RESULTAT_ANALYSE',

            addToilettageActivity: 'sanitaire/ADD_TOILETTAGE_ACTIVITY',
            updateToilettageActivity: 'sanitaire/UPDATE_TOILETTAGE_ACTIVITY',
            deleteToilettageActivity: 'sanitaire/DELETE_TOILETTAGE_ACTIVITY',

            addToilettage: 'sanitaire/ADD_TOILETTAGE',
            updateToilettage: 'sanitaire/UPDATE_TOILETTAGE',
            deleteToilettage: 'sanitaire/DELETE_TOILETTAGE'

        })
    },
    apollo: {
        $subscribe: {
          section: {
                query: SUBSCRIBE_SECTION,
                result({data}){
                    
                    let s = data.section
                    if(s.type === ADD) this.addSection(s.data)
                    if(s.type === DELETE) this.deleteSection(s.data)
                    if(s.type === UPDATE) this.updateSection(s.data)
                }
            },
            question: {
                query: SUBSCRIBE_QUESTION,
                result({data}){
                    let s = data.question
                    if(s.type === ADD) this.addQuestion(s.data)
                    if(s.type === DELETE) this.deleteQuestion(s.data)
                    if(s.type === UPDATE) this.updateQuestion(s.data)
                }
            },
            response: {
                query: SUBSCRIBE_RESPONSE,
                result({data}){
                    let s = data.response
                    if(s.type === ADD) this.addResponse(s.data)
                    if(s.type === DELETE) this.deleteResponse(s.data)
                    if(s.type === UPDATE) this.updateResponse(s.data)
                }
            },
            anamnese: {
                query: SUBSCRIBE_ANAMNESE,
                result({data}){
                    
                    let a = data.anamnese
                    if(a.type === ADD) this.addAnamnese(a.data)
                    if(a.type === DELETE) this.deleteAnamnese(a.data)
                    if(a.type === UPDATE) this.updateAnamnese(a.data)
                }
            },
            examen_clinique: {
                query: SUBSCRIBE_EXAMEN_CLINIQUE,
                result({data}) {
                    let e = data.examen_clinique
                    if(e.type === ADD) this.addExamenClinique(e.data)
                    if(e.type === UPDATE) this.updateExamenClinique(e.data)
                    if(e.type === DELETE) this.deleteExamenClinique(e.data)
                }
            },
            analyse: {
                query: SUBSCRIBE_ANALYSE ,
                result({data}){
                    
                    let analyseType = data.analyse
                    if(analyseType.type === ADD) this.addAnalyse(analyseType.data)
                    if(analyseType.type === DELETE) this.deleteAnalyse(analyseType.data)
                    if(analyseType.type === UPDATE) this.updateAnalyse(analyseType.data)
                }
            },
            resultat_analyse: {
                query: SUBSCRIBE_RESULTAT_ANALYSE, 
                result({data}){
                    let resultat = data.resultat_analyse
                    if(resultat.type === ADD) this.addResultat(resultat.data)
                    if(resultat.type === DELETE) this.deleteResultat(resultat.data)
                }
            },
            type_analyse: {
                query: SUBSCRIBE_TYPE_ANALYSE,
                result({data}){
                    let t = data.type_analyse
                    if(t.type === ADD) this.addTypeAnalyse(t.data)
                    if(t.type === DELETE) this.deleteTypeAnalyse(t.data)
                    if(t.type === UPDATE) this.updateTypeAnalyse(t.data)
                }
            },
            categorie_analyse: {
                query: SUBSCRIBE_CATEGORIE_ANALYSE,
                result({data}){
                    let c = data.categorie_analyse
                    if(c.type === ADD) this.addCategorieAnalyse(c.data)
                    if(c.type === DELETE) this.deleteCategorieAnalyse(c.data)
                    if(c.type === UPDATE) this.updateCategorieAnalyse(c.data)
                }
            },
            type_recherche: {
                query: SUBSCRIBE_TYPE_RECHERCHE,
                result({data}){
                    let t = data.type_recherche
                    if(t.type === ADD) this.addTypeRecherche(t.data)
                    if(t.type === DELETE) this.deleteTypeRecherche(t.data)
                    if(t.type === UPDATE) this.updateTypeRecherche(t.data)
                }
            },
            examen:{
                query: SUBSCRIBE_EXAMEN,
                result({data}){
                    let e = data.examen
                    if(e.type === ADD) this.addExamen(e.data)
                    if(e.type === DELETE) this.deleteExamen(e.data)
                    if(e.type === UPDATE) this.updateExamen(e.data)
                }
            },
            type_maladie: {
                query: SUBSCRIBE_TYPE_MALADIE,
                result({data}){
                    let t = data.type_maladie
                    if(t.type === ADD) this.addTypeMaladie(t.data)
                    if(t.type === DELETE) this.deleteTypeMaladie(t.data)
                    if(t.type === UPDATE) this.updateTypeMaladie(t.data)
                }
            },
            maladie: {
                query: SUBSCRIBE_MALADIE,
                result({data}){
                    let m = data.maladie
                    if(m.type === ADD) this.addMaladie(m.data)
                    if(m.type === DELETE) this.deleteMaladie(m.data)
                    if(m.type === UPDATE) this.updateMaladie(m.data)
                }
            },
            famille_medicament: {
                query: SUBSCRIBE_FAMILLE_MEDICAMENT,
                result({data}){
                    let fm = data.famille_medicament
                    if(fm.type === ADD) this.addFamilleMedicament(fm.data)
                    if(fm.type === DELETE) this.deleteFamilleMedicament(fm.data)
                    if(fm.type === UPDATE) this.updateFamilleMedicament(fm.data)
                }
            },
            medicament: {
                query: SUBSCRIBE_MEDICAMENT,
                result({data}){
                    let m = data.medicament
                    if(m.type === ADD) this.addMedicament(m.data)
                    if(m.type === DELETE) this.deleteMedicament(m.data)
                    if(m.type === UPDATE) this.updateMedicament(m.data)
                }
            },
            vaccin: {
                query: SUBSCRIBE_VACCIN,
                result({data}){
                    let v = data.vaccin
                    if(v.type === ADD) this.addVaccin(v.data)
                    if(v.type === DELETE) this.deleteVaccin(v.data)
                    if(v.type === UPDATE) this.updateVaccin(v.data)
                }
            },
            type_parasite: {
                query: SUBSCRIBE_TYPE_PARASITE,
                result({data}){
                    let tp = data.type_parasite
                    if(tp.type === ADD) this.addTypeParasite(tp.data)
                    if(tp.type === DELETE) this.deleteTypeParasite(tp.data)
                    if(tp.type === UPDATE) this.updateTypeParasite(tp.data)
                }
            },
            anti_parasitaire: {
                query: SUBSCRIBE_ANTI_PARASITAIRE,
                result({data}){
                    let ap = data.anti_parasitaire
                    if(ap.type === ADD) this.addAntiParasitaire(ap.data)
                    if(ap.type === DELETE) this.deleteAntiParasitaire(ap.data)
                    if(ap.type === UPDATE) this.updateAntiParasitaire(ap.data)
                }
            },
            vaccination: {
                query: SUBSCRIBE_VACCINATION,
                result({data}){
                    let v = data.vaccination
                    if(v.type === ADD) this.addVaccination(v.data)
                    if(v.type === DELETE) this.deleteVaccination(v.data)
                    if(v.type === UPDATE) this.updateVaccination(v.data)
                }
            },
            traitement: {
                query: SUBSCRIBE_TRAITEMENT,
                result({data}){
                    let t = data.traitement
                    if(t.type === ADD) this.addTraitement(t.data)
                    if(t.type === DELETE) this.deleteTraitement(t.data)
                    if(t.type === UPDATE) this.updateTraitement(t.data)
                }
            },
            type_pronostic: {
                query: SUBSCRIBE_TYPE_PRONOSTIC,
                result({data}){
                    let t = data.type_pronostic
                    if(t.type === ADD) this.addTypePronostic(t.data)
                    if(t.type === DELETE) this.deleteTypePronostic(t.data)
                    if(t.type === UPDATE) this.updateTypePronostic(t.data)
                }
            },
            pronostic: {
                query: SUBSCRIBE_PRONOSTIC,
                result({data}){
                    let p = data.pronostic
                    if(p.type === ADD) this.addPronostic(p.data)
                    if(p.type === DELETE) this.deletePronostic(p.data)
                    if(p.type === UPDATE) this.updatePronostic(p.data)
                }
            },
            motif_consultation: {
                query: SUBSCRIBE_MOTIF_CONSULTATION,
                result({data}){
                    let m = data.motif_consultation
                    if(m.type === ADD) this.addMotifConsultation(m.data)
                    if(m.type === DELETE) this.deleteMotifConsultation(m.data)
                    if(m.type === UPDATE) this.updateMotifConsultation(m.data)
                }
            },
            type_chirurgie: {
                query: SUBSCRIBE_TYPE_CHIRURGIE,
                result({data}){
                    let t = data.type_chirurgie
                    if(t.type === ADD) this.addTypeChirurgie(t.data)
                    if(t.type === DELETE) this.deleteTypeChirurgie(t.data)
                    if(t.type === UPDATE) this.updateTypeChirurgie(t.data)
                }
            },
           chirurgie: {
                query: SUBSCRIBE_CHIRURGIE,
                result({data}){
                    let t = data.chirurgie
                    if(t.type === ADD) this.addChirurgie(t.data)
                    if(t.type === DELETE) this.deleteChirurgie(t.data)
                    if(t.type === UPDATE) this.updateChirurgie(t.data)
                }
            },

            modevie: {
                query: SUBSCRIBE_MODE_VIE,
                result({data}){
                    let m = data.modevie
                    if(m.type === ADD) this.addModeVie(m.data)
                    if(m.type === DELETE) this.deleteModeVie(m.data)
                    if(m.type === UPDATE) this.updateModeVie(m.data)
                }
            },

            fiche_analyse: {
                query: SUBSCRIBE_FICHE_ANAYLSE,
                result({data}){
                    let m = data.fiche_analyse
                    if(m.type === ADD) this.addFicheAnalyse(m.data)
                    if(m.type === DELETE) this.deleteFicheAnalyse(m.data)
                    if(m.type === UPDATE) this.updateFicheAnalyse(m.data)
                }
            },
            section_fiche: {
                query: SUBSCRIBE_SECTION_FICHE,
                result({data}){
                    let m = data.section_fiche
                    if(m.type === ADD) this.addFicheSection(m.data)
                    if(m.type === DELETE) this.deleteFicheSection(m.data)
                    if(m.type === UPDATE) this.updateFicheSection(m.data)
                }
            },
            parametre_fiche: {
                query: SUBSCRIBE_PARAMETRE_FICHE,
                result({data}){
                    let m = data.parametre_fiche
                    if(m.type === ADD) this.addFicheParametre(m.data)
                    if(m.type === DELETE) this.deleteFicheParametre(m.data)
                    if(m.type === UPDATE) this.updateFicheParametre(m.data)
                }
            },
            reference_fiche: {
                query: SUBSCRIBE_REFERENCE_FICHE,
                result({data}){
                    let m = data.reference_fiche
                    if(m.type === ADD) this.addFicheReference(m.data)
                    if(m.type === DELETE) this.deleteFicheReference(m.data)
                    if(m.type === UPDATE) this.updateFicheReference(m.data)
                }
            },

            type_fiche_analyse: {
                query: SUBSCRIBE_TYPE_FICHE_ANAYLSE,
                result({data}){
                    let m = data.type_fiche_analyse
                    if(m.type === ADD) this.addTypeFicheAnalyse(m.data)
                    if(m.type === DELETE) this.deleteTypeFicheAnalyse(m.data)
                    if(m.type === UPDATE) this.updateTypeFicheAnalyse(m.data)
                }
            },
            resultat_analyse_fiche: {
                query: SUBSCRIBE_RESULTAT_ANALYSE_FICHE,
                result({data}){
                    let m = data.resultat_analyse_fiche
                    if(m.type === ADD) this.addResultatAnalyseFiche(m.data)
                    if(m.type === UPDATE) this.updateResultatAnalyseFiche(m.data)
                    if(m.type === DELETE) this.deleteResultatAnalyseFiche(m.data)
                }
            },
            toilettage_activity: {
              query: SUBSCRIBE_TOILETTAGE_ACTIVITY, 
              result({data}){
                    let m = data.toilettage_activity
                    if(m.type === ADD) this.addToilettageActivity(m.data)
                    if(m.type === UPDATE) this.updateToilettageActivity(m.data)
                    if(m.type === DELETE) this.deleteToilettageActivity(m.data)
                }
            },
            toilettage: {
              query: SUBSCRIBE_TOILETTAGE, 
              result({data}){
                    let m = data.toilettage
                    if(m.type === ADD) this.addToilettage(m.data)
                    if(m.type === UPDATE) this.updateToilettage(m.data)
                    if(m.type === DELETE) this.deleteToilettage(m.data)
                }
            }
        }
    },
    computed: {
      ...mapGetters({
          is_admin: 'auth/is_admin',
          is_super_admin: 'auth/is_super_admin',
          is_veterinaire: 'auth/is_veterinaire',
          is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
          is_assistant_veto_major: 'auth/is_assistant_veto_major',
      })
    }
}
</script>

<style>

</style>