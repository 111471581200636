/** USER */
export const ADD_USER = "add_user"
export const DEACTIVATE_USER = "deactivate_user"
export const ACTIVATE_USER = "activate_user"
export const CONNECTED_USER = "connected_user"


/** ANIMAL */
export const ANIMAL = 'animal'

/** RDV */
export const RDV = 'rdv'
export const CANCEL_RDV = 'cancel_rdv'
export const UPDATE_RDV = 'update_rdv'
export const VALIDATE_RDV = 'validate_rdv'