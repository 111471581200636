export const getToken = (item) => {
  return window.localStorage.getItem(item)
}

export const saveToken = (item, token) => {
  window.localStorage.setItem(item, token)
}

export const destroyToken = (item) => {
  window.localStorage.removeItem(item)
}

export default { getToken, saveToken, destroyToken }
