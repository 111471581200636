<template>
  <header class="header navbar navbar-expand-sm">
    <ul class="navbar-item theme-brand flex-row  text-center">
      <li
        class="nav-item theme-logo"
        id="logo"
      >
        <router-link
          :to="{name: 'home'}"
        > 
          <img
            :src="logo"
            class="navbar-logo"
            alt="logo"
          >
        </router-link>
      </li>
      
    </ul>
    <ul class="navbar-item flex-row ml-md-0 ml-auto">
      <li class="nav-item align-self-center search-animated">
        <i class="las la-search toggle-search" />
        <form
          class="form-inline search-full form-inline search"
          action="https://xatoadmin-demo1.web.app/ltr/pages_search_result.html"
          role="search"
        >
          <div class="search-bar">
            <input
              type="text"
              class="form-control search-form-control  ml-lg-auto"
              placeholder="Search here"
            >
          </div>
        </form>
      </li>
    </ul>
    <ul
      class="navbar-item flex-row ml-md-auto"
      style="padding-right: 20px;"
    >
      <li class="nav-item dropdown fullscreen-dropdown d-none d-lg-flex">
        <a
          class="nav-link full-screen-mode"
          href="javascript:void(0);"
        >
          <i
            class="las la-compress la-2x"
            id="fullScreenIcon"
          />
        </a>
      </li>
      <li class="nav-item dropdown message-dropdown">
        <a 
          href="javascript:void(0)" 
          class="nav-link dropdown-toggle position-relative" 
          id="notificationDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="las la-bell la-2x" />
          <!-- <div class="blink">
              <div class="circle"></div>
          </div> -->
        </a>
        <div class="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
          <div class="nav-drop is-notification-dropdown" >
              <div class="inner">
                  <div class="nav-drop-header">
                        <span class="text-black font-12 strong">{{ countUnreadNotifications  }}</span>
                        <a class="text-muted font-12" href="#">
                          Supprimer Tout
                        </a>
                  </div>
                  <div class="nav-drop-body account-items pb-0">
                      <notification v-for="(n, index) in sortedNotifications" :key="index" :notification="n"/>
                      <hr class="account-divider">
                      <div class="text-center">
                          <router-link :to="{name: 'notifications'}" class="text-primary strong font-13" href="#">Voir Tout</router-link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </li>




    <li class="nav-item dropdown message-dropdown">
        <a
          href="javascript:void(0);"
          class="nav-link dropdown-toggle user"
          id="userProfileDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <!-- <img src="assets/img/profile-16.jpg" alt="avatar"> -->
          <i class="las la-user la-2x" />
        </a>
        <div
          class="dropdown-menu position-absolute"
          aria-labelledby="userProfileDropdown"
        >
          <div class="nav-drop is-account-dropdown">
            <div class="inner">
              <div class="nav-drop-header">
                <span
                  class="text-primary"
                  style="font-size: 14px"
                >Welcome {{ fullname }} !</span>
              </div>
              <div class="nav-drop-body account-items pb-0">
                <router-link
                  id="profile-link"
                  class="account-item"
                  :to="{name: 'profile'}"
                >
                  <div class="media align-center">
                    <div class="media-left">
                      <div class="image">
                        <i class="las la-user la-2x" />
                      </div>
                    </div>
                    <div class="media-content ml-3">
                      <h6 class="font-13 mb-0 strong">
                        {{ fullname }}
                      </h6>
                      <small>{{ typeCompte }}</small>
                    </div>
                    <div class="media-right">
                      <i data-feather="check" />
                    </div>
                  </div>
                </router-link>
                <hr class="account-divider">
                <router-link
                  class="account-item"
                  :to="{name: 'rendez_vous'}"
                >
                  <div class="media align-center">
                    <div class="icon-wrap">
                      <i class="las la-calendar font-20" />
                    </div>
                    <div class="media-content ml-3">
                      <h6 class="font-13 mb-0 strong">
                        Rendez-vous
                      </h6>
                    </div>
                  </div>
                </router-link>              
                <hr class="account-divider">
                <a
                  class="account-item"
                  @click="deconnectUser"
                >
                  <div class="media align-center">
                    <div class="icon-wrap">
                      <i class="las la-sign-out-alt font-20" />
                    </div>
                    <div class="media-content ml-3">
                      <h6 class="font-13 mb-0 strong ">
                        Logout
                      </h6>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
    </li>
  </ul>
    <!-- <ul class="navbar-item flex-row">
            <li class="nav-item dropdown header-setting">
                <a href="javascript:void(0);" class="nav-link dropdown-toggle rightbarCollapse" data-placement="bottom">
                    <i class="las la-sliders-h"></i>
                </a>
            </li>
        </ul> -->
  </header>
</template>

<script>
const logo = require('../../assets/img/logo256.png')
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import { SUBSCRIBER_EVENT, SUBSCRIBER_NOTIFICATION } from '../../graphql/events'
import { ADD, DELETE, UPDATE } from '../../constants/app'
import Notification from './notification.vue'
export default {
    components: {Notification},
    data() {
        return {
            logo
        }
        
    },
    methods: {
        ...mapMutations({
            logout: 'auth/LOGOUT_USER',
            addEvent: 'events/ADD_EVENT',
            updateEvent: 'events/UPDATE_EVENT',
            deleteEvent: 'events/DELETE_EVENT',
            addNotification: 'notification/ADD_NOTIFICATION',
            updateNotification: 'notification/UPDATE_NOTIFICATION',
            deleteNotification: 'notification/DELETE_NOTIFICATION'
        }),
        deconnectUser(){
            this.logout()
            location.reload()
        }
    },
    apollo: {
        $subscribe: {
          event: {
                query: SUBSCRIBER_EVENT,
                result({data}){
                    let s = data.event
                    if(s.type === ADD) this.addEvent(s.data)
                    if(s.type === DELETE) this.deleteEvent(s.data)
                    if(s.type === UPDATE) this.updateEvent(s.data)
                }
            },
            notification: {
                query: SUBSCRIBER_NOTIFICATION,
                variables () {
                    return {
                        uid: this.me.uid
                    }
                },
                skip () {
                    return this.me === null
                },
                result({data}){
                    let n = data.notification
                    if(n.type === ADD) this.addNotification(n.data)
                    if(n.type === DELETE) this.deleteNotification(n.data)
                    if(n.type === UPDATE) this.updateNotification(n.data)
                }
            },
          }
    },
    computed: {
        ...mapGetters({
            me: 'auth/me',
            typeUser: 'auth/typeUtilisateur',
            notifications: 'notification/notifications',
            hasUnreadNotification: 'notification/hasUnreadNotification',
            unreadNotifications: 'notification/unreadNotifications'
        }),
        sortedNotifications(){
          return [...this.notifications].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        countUnreadNotifications(){
          let n = this.unreadNotifications.length
          return n > 1 ? `${n} Notifications` : `${n} Notification`
        },
        fullname(){
            if(this.me !== null) return this.me.fullname
            return ''
        },
        typeCompte(){
            if(this.typeUser !== null && this.typeUser !== undefined) return this.typeUser.libelle
            return ''
        }
    }
}
</script>

<style scoped>
.is-notification-dropdown {
    width: 300px !important;
}
</style>