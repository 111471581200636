import gql from 'graphql-tag'

export const ADD_GROUPE = gql`
mutation Mutation($groupe: GroupeInput!){
    addGroupe(groupe: $groupe)
}
`
export const UPDATE_GROUPE = gql`
  mutation Mutation($uid: String!, $groupe: GroupeInput!){
    updateGroupe(uid: $uid, groupe: $groupe)
  }
`
export const DELETE_GROUPE = gql`
mutation Mutation($uid: String!) {
    deleteGroupe(uid: $uid)
}
`

export const SUBSCRIBER_GROUPE = gql`
subscription Subscription {
  groupe {
    type
    data {
      uid
      name
      description
      createBy
      createdAt
      members{
        user
      }
    }
  }
}
`

export const ADD_GROUP_PARAM = gql`
mutation Mutation($param: GroupParamInput!){
    addGroupParam(param: $param)
}
`
export const DELETE_GROUP_PARAM = gql`
mutation Mutation($uid: String!) {
    deleteGroupParam(uid: $uid)
}
`

export const SUBSCRIBER_GROUP_PARAM = gql`
subscription Subscription {
    group_param {
        type
        data {
            uid
            groupe
            type
            value        }
    }
}
`

export const ADD_GROUP_MEMBERS = gql`
mutation Mutation($members: GroupMemberInput!){
    addMembers(members: $members)
}
`

export const DELETE_MEMBRES = gql`
mutation Mutation($groupe: String!){
    deleteMembers(groupe: $groupe)
}
`