import gql from 'graphql-tag'

/** FICHE_ANAYLSE */
export const ADD_FICHE_ANAYLSE = gql`
mutation Mutation($fiche: FicheAnalyseInput!) {
    addFicheAnalyse(fiche: $fiche)
}
`
export const UPDATE_FICHE_ANAYLSE = gql`
mutation Mutation($uid: String!, $fiche: FicheAnalyseInput!) {
    updateFicheAnalyse(uid: $uid, fiche: $fiche)
}
`
export const DELETE_FICHE_ANAYLSE = gql`
mutation Mutation($uid: String!) {
    deleteFicheAnalyse(uid: $uid)
}
`
export const SUBSCRIBE_FICHE_ANAYLSE= gql`
subscription Subscription {
    fiche_analyse {
    type
    data {
      uid
      libelle
      categorieAnalyse
    }
  }
}`

/** TYPE_FICHE_ANAYLSE */
export const ADD_TYPE_FICHE_ANAYLSE = gql`
mutation Mutation($type: TypeFicheAnalyseInput!) {
  addTypeFicheAnalyse(type: $type)
}
`
export const UPDATE_TYPE_FICHE_ANAYLSE = gql`
mutation Mutation($uid: String!, $type: TypeFicheAnalyseInput!) {
  updateTypeFicheAnalyse(uid: $uid, type: $type)
}
`
export const DELETE_TYPE_FICHE_ANAYLSE = gql`
mutation Mutation($uid: String!) {
  deleteTypeFicheAnalyse(uid: $uid)
}
`
export const SUBSCRIBE_TYPE_FICHE_ANAYLSE= gql`
subscription Subscription {
  type_fiche_analyse {
    type
    data {
      uid
      libelle
      code
    }
  }
}`

/** SECTION_FICHE */
export const ADD_SECTION_FICHE = gql`
mutation Mutation($section: SectionFicheInput!) {
  addSectionFiche(section: $section)
}
`
export const UPDATE_SECTION_FICHE = gql`
mutation Mutation($uid: String!, $section: SectionFicheInput!) {
  updateSectionFiche(uid: $uid, section: $section)
}
`
export const DELETE_SECTION_FICHE = gql`
mutation Mutation($uid: String!) {
  deleteSectionFiche(uid: $uid)
}
`
export const SUBSCRIBE_SECTION_FICHE= gql`
subscription Subscription {
  section_fiche {
    type
    data {
      uid
      libelle
      fiche
    }
  }
}`


/** PARAMETRE_FICHE */
export const ADD_PARAMETRE_FICHE = gql`
mutation Mutation($parametre: ParametreFicheInput!) {
  addParametreFiche(parametre: $parametre)
}
`
export const UPDATE_PARAMETRE_FICHE = gql`
mutation Mutation($uid: String!, $parametre: ParametreFicheInput!) {
  updateParametreFiche(uid: $uid, parametre: $parametre)
}
`
export const DELETE_PARAMETRE_FICHE = gql`
mutation Mutation($uid: String!) {
  deleteParametreFiche(uid: $uid)
}
`
export const SUBSCRIBE_PARAMETRE_FICHE= gql`
subscription Subscription {
  parametre_fiche {
    type
    data {
      libelle
      fiche
      uid
      section
    }
  }
}`

/** REFERENCE_FICHE */
export const ADD_REFERENCE_FICHE = gql`
mutation Mutation($reference: ReferenceFicheInput!) {
  addReferenceFiche(reference: $reference)
}
`
export const UPDATE_REFERENCE_FICHE = gql`
mutation Mutation($uid: String!, $reference: ReferenceFicheInput!) {
  updateReferenceFiche(uid: $uid, reference: $reference)
}
`
export const DELETE_REFERENCE_FICHE = gql`
mutation Mutation($uid: String!) {
  deleteReferenceFiche(uid: $uid)
}
`
export const SUBSCRIBE_REFERENCE_FICHE= gql`
subscription Subscription {
  reference_fiche {
    type
    data {
      uid
      valeur
      espece
      parametre
    }
  }
}`

/** RESULTAT_ANALYSE_FICHE */
export const ADD_RESULTAT_ANALYSE_FICHE = gql`
mutation Mutation($resultat: ResultatAnalyseFicheInput!) {
  addResultatAnalyseFiche(resultat: $resultat)
}
`
export const UPDATE_RESULTAT_ANALYSE_FICHE = gql`
mutation Mutation($uid: String!, $resultat: ResultatAnalyseFicheInput!) {
  updateResultatAnalyseFiche(uid: $uid, resultat: $resultat)
}
`
export const DELETE_RESULTAT_ANALYSE_FICHE = gql`
mutation Mutation($uid: String!) {
  deleteResultatAnalyseFiche(uid: $uid)
}
`
export const SUBSCRIBE_RESULTAT_ANALYSE_FICHE= gql`
subscription Subscription {
  resultat_analyse_fiche {
    type
    data {
      uid
      analyse
      data
      commentaire
      info
      createBy
      createdAt
    }
  }
}`