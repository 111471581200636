<template>
  <div>
    Tutorials
  </div>
</template>

<script>
import tour from '../tutorial'
import {mapGetters} from 'vuex'
import hopscotch from '../assets/plugins/hopscotch/hopscotch'
import {saveToken, getToken} from '../plugins/localstorage'
import {ID_TOUR} from '../constants/app'
require('../assets/plugins/hopscotch/hopscotch.min.css')

export default {
    data(){
        return {
          allowed: false
        }
    },
    computed : {
      ...mapGetters({
        me: 'auth/me',
        typeUser: 'auth/typeUtilisateur'
      }),
      tourContent(){
        return tour
      },
    },
    mounted(){
      let allowedType = tour.allowedType
      this.allowed = allowedType.includes("all") || allowedType.includes(this.typeUser.code)
      hopscotch.configure({
        i18n :  {
          nextBtn: "Suv.",
          prevBtn: "Prec.",
          doneBtn: "Fin",
          skipBtn: "Ignorer",
          closeTooltip: "Fermer"
        },
        showSkip: true,
        showPrevButton: true,
        showCTAButton : true,
        onStart: () => {
          
        },
        onEnd: () => {
          saveToken(ID_TOUR, tour.id)
        }
      })
      if(!this.alreadyTour(tour.id) && this.allowed){
        hopscotch.startTour(tour)
      }
      
    },
    methods: {
      alreadyTour(id){
        if(!getToken(ID_TOUR)) return false
        return id === getToken(ID_TOUR)
      }
      
    }

}
</script>

<style>

</style>