const state = {
    groups: [],
    params: [],
    nbFiltre: 0,
    filtres: [],
    selectAll: false,
    members: []
}

const getters = {
    groups: state => state.groups,
    params: state => state.params,
    nbFiltre: state => state.nbFiltre,
    filtres: state => state.filtres,
    selectAll: state => state.selectAll,
    members: state => state.members,
}

const mutations = {
    SET_GROUPS: (state, list) => {state.groups = list},
    ADD_GROUP: (state, group) => {state.groups.push(group)},
    UPDATE_GROUP: (state, group) => {state.groups = state.groups.filter(item => item.uid !== group.uid); state.groups.push(group)},
    DELETE_GROUP: (state, group) => {
        state.groups = state.groups.filter(item => item.uid !== group.uid)
        state.params = state.params.filter(item => item.groupe !== group.uid)
        state.filtres = state.filtres.filter(item => item.groupe !== group.uid)
        state.members = state.members.filter(item => item.groupe !== group.uid)
    } ,

    SET_PARAMS: (state, list) => {state.params = list},
    ADD_PARAM: (state, param) => {state.params.push(param)},
    UPDATE_PARAM: (state, param) => {state.params = state.params.filter(item => item.uid !== param.uid); state.params.push(param)},
    DELETE_PARAM: (state, param) => {
        state.params = state.params.filter(item => item.uid !== param.uid)
        state.filtres = state.filtres.filter(item => item.uid !== param.uid)
    },

    INIT_FILTRES:(state) => {state.filtres = []; state.nbFiltre = 0},
    INCREMENT_NB_FILTRE: (state) => {state.nbFiltre += 1 },
    DECREMENT_NB_FILTRE: (state) => {state.filtres = state.filtres.filter(item => item.order !== state.nbFiltre); state.nbFiltre -= 1 },
    SET_FILTRES: (state, list) => {state.filtres = list},
    ADD_FILTRE: (state, filtre) => {state.filtres.push(filtre)},
    UPDATE_FILTRE: (state, filtre) => {state.filtres = state.filtres.filter(item => item.order !== filtre.order); state.filtres.push(filtre)},
    REMOVE_FILTRE: (state, order) => {state.filtres = state.filtres.filter(item => item.order !== order)},

    SET_SELECT_ALL: (state, select) => {state.selectAll = select},

    SET_MEMBERS: (state, list) => {state.members = list},
    ADD_MEMBER: (state, member)=> {state.members.push(member)},
    DELETE_MEMBER: (state, member)=> {state.members = state.members.filter(item => item.user !== member.user)}
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}