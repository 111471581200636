<template>
  <a class="account-item" href="#">
    <div class="media align-center">
        <div class="icon-wrap">
            <i class="las font-20" :class="icon"></i>
        </div>
        <div class="media-content ml-3">
            <h6 class="font-13 mb-0 strong">{{ notification.libelle }}</h6>
            <p class="m-0 mt-1 font-10 text-muted">{{ notification.createdAt|showTime}}</p>
        </div>
    </div>
    </a>
</template>

<script>
// import { mapGetters } from 'vuex';
import {
    ANIMAL,
    ADD_USER, DEACTIVATE_USER, ACTIVATE_USER,
    RDV, CANCEL_RDV, UPDATE_RDV, VALIDATE_RDV, CONNECTED_USER
} from '../../constants/notification'
export default {
    props: {
        notification: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        icon(){
            let type = this.notification.type
            switch(type){
                case ADD_USER: return 'la-user-plus';
                case DEACTIVATE_USER: return 'la-user-times';
                case ACTIVATE_USER: return 'la-user-check';
                case CONNECTED_USER: return 'la-user-shield'


                case ANIMAL: return 'la-dog';
                case RDV: return 'la-calendar';
                case CANCEL_RDV: return 'la-calendar-times';
                case UPDATE_RDV: return 'la-calendar-day';
                case VALIDATE_RDV: return 'la-calendar-check'


                default: return 'la-info-circle'
            }
        }
    }

}
</script>

<style>

</style>