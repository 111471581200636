const state = {
    types: [],
    events: [],
    selectedEventUid: null,
}


const getters = {
    types: state => state.types,
    events: state => state.events,
    selectedEventUid: state => state.selectedEventUid
}

const mutations = {
    SET_TYPES: (state, list) => {state.types  = list},
    ADD_TYPE: (state, type) => {state.types.push(type)},
    UPDATE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid); state.types.push(type)},
    DELETE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid)},

    SET_EVENTS: (state, list) => {state.events = list},
    ADD_EVENT: (state, event) => {state.events.push(event)},
    UPDATE_EVENT: (state, event) => {state.events = state.events.filter(item =>item.uid !== event.uid); state.events.push(event)},
    DELETE_EVENT: (state, event) => {state.events = state.events.filter(item => item.uid !== event.uid)},

    SET_SELECTED_EVENT_UID: (state, uid) => {state.selectedEventUid = uid}
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}