import Vue from 'vue'
require('bootstrap')
require('select2')
require('./app')
require('./custom')


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(fas)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


