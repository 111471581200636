export const BASE = (process.env.NODE_ENV === 'production')
? 'caseac.back-end.agro-expertise.com'
: 'localhost:3000'

export const BASE_URL = (process.env.NODE_ENV === 'production')
? 'https://'+BASE
: 'http://'+BASE

export const GRAPHQL_API_ROOT =  (process.env.NODE_ENV === 'production')
? BASE_URL+'/graphql'
: BASE_URL+'/graphql'

export const WS_ROOT =  (process.env.NODE_ENV === 'production')
? 'wss:'+BASE+'/graphql'
: 'ws:'+BASE+'/graphql'

export const API_VERSION = '1.0.0'
export const USERID = 'uid'
export const ID_TOKEN_KEY = 'app_token'
export const ID_TOUR = "app_tour"
export const PERPAGE_KEY = 'dt_perpage'
export const TOKEN_EXPIRE = 'expireAt'
export const DEFAULT_LANGUAGE = 'fr'
export const ADD = 'ADD'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'

export const TYPE_ADMIN = 'admin'
export const TYPE_SUPER_ADMIN = 'super_admin'
export const TYPE_PROPRIO = 'proprio'
export const TYPE_VETERIANIRE = 'veto' 
export const TYPE_LABORATOIRE = 'labo'
export const TYPE_TECHNICIEN_IMAGERIE = 'tech_imagerie'
export const TYPE_ASSISTANT_VETERINAIRE = 'assistant_veto'
export const TYPE_ASSISTANT_VETERINAIRE_MAJOR = 'assistant_veto_major'


export const MODE_EDIT = 'edit'
export const MODE_ADD = 'add'

export const HEURE_ARRIVE='7:00'
export const HEURE_DEPART='18:00'


export const DASHBOARD_BEST_MEDICAMENT = 5
export const DASHBOARD_TOP_CLIENT = 10
export const CLINIQUE_NAME = "Saintes Véronique et Brigitte"
export const CLINIQUE_FICHE_NAME = "G.A.E - CLINIQUE STES VERONIQUE & BRIGITTE"


export const TRCS = [
      {libelle: 'Inferieur 3s', value: 2.9},
      {libelle: 'Superieur 3s', value: 3.1}
    ]
export const CURRENCIES = [
  {libelle: 'F CFA', value: 'fcfa'},
  {libelle: 'Euro', value: '€'},
  {libelle: 'Dollar US', value: '$ US'}
]
export const ANALYSE_REFERENCE_VALEUR = 'reference_valeur'
export const ANALYSE_INTERPRETATION = 'interpretation'
export const ANALYSE_RESULTAT_DIRECT = 'resultat_direct'
